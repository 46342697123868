<template>
  <div class="layout-border">
    <div class="mainCont">
      <div class="background">
        <Star :totalStarNumber="totalStars" :currentIndex="currentIndex" />
        <div class="game-content" :class="{ 'number-bg-color': !lessonType }">
          <div class="game-left-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in asideElementInfo.leftList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': item.done }"
                @click="foundAnswer(item)"
                v-show="lessonType === 0"
              >
                <span class="pinyin font-pinyin-medium" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span>
              </div>

              <div
                v-for="(item, index) in asideElementInfo.leftList"
                :key="index + 'type-imgleft'"
                class="card-item"
                @click="foundAnswer(item)"
                v-show="lessonType === 1"
              >
                <img
                  :src="item.bgImg"
                  alt=""
                  class="card"
                  :class="{
                    yellowBorder: clickIndexList.indexOf(item.id) > -1,
                    redBorder:
                      bingoIndexList.indexOf(item.id) > -1 &&
                      currentIndex === questionInfoList.length,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="game-middle-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in asideElementInfo.MiddleList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': item.done }"
                @click="foundAnswer(item)"
                v-show="lessonType === 0"
              >
                <span class="pinyin font-pinyin-medium" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span>
              </div>
              <div
                v-for="(item, index) in asideElementInfo.MiddleList"
                :key="index + 'type-imgMiddle'"
                class="card-item"
                @click="foundAnswer(item)"
                v-show="lessonType === 1"
              >
                <img
                  :src="item.bgImg"
                  alt=""
                  class="card"
                  :class="{
                    yellowBorder: clickIndexList.indexOf(item.id) > -1,
                    redBorder:
                      bingoIndexList.indexOf(item.id) > -1 &&
                      currentIndex === questionInfoList.length,
                  }"
                />
              </div>
            </div>
          </div>

          <div class="game-right-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in asideElementInfo.rightList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': item.done }"
                @click="foundAnswer(item)"
                v-show="lessonType === 0"
              >
                <span class="pinyin font-pinyin-medium" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span>
              </div>
              <div
                v-for="(item, index) in asideElementInfo.rightList"
                :key="index + 'type-imgRight'"
                class="card-item"
                @click="foundAnswer(item)"
                v-show="lessonType === 1"
              >
                <img
                  :src="item.bgImg"
                  alt=""
                  class="card"
                  :class="{
                    yellowBorder: clickIndexList.indexOf(item.id) > -1,
                    redBorder:
                      bingoIndexList.indexOf(item.id) > -1 &&
                      currentIndex === questionInfoList.length,
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            :style="[
              lessonType
                ? {
                    backgroundImage: `url(${require('@/assets/img/16-GAMES/G56-bingo-images/background-card-panda.svg')})`,
                  }
                : '',
            ]"
            :class="{ imgType: lessonType }"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <img class="objectDisplay" :src="item.bgImg" v-if="!lessonType" />
            <!-- 中间的数字和pinyin -->
            <div class="number-area" v-if="lessonType">
              <div class="pinyin font-pinyin-medium">{{ item.pinyin }}</div>
              <div class="font-hanzi-medium">{{ item.hanzi }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";

import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  components: {
    PageButton,
    Star,
  },
  props: {
    asideElementInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    questionInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bingoIndexList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonType: {
      type: Number,
      default: 0,
    },
    totalStars: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      gameSocket: {},
      isLastStep: false,
      currentIndex: 0,
      question: 0,
      clickIndexList: [],

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on("clicBingoGame", ({ index }) => {
      this.foundAnswer(index, true);
    });
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20040,
          data: { value },
          text: "clicBingoGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },

    updateNumbers(value) {
      this.numBers = value;
    },
    foundAnswer(index, isFromSocket = false) {
      // console.log(index);
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random(),
        };
      }
      if (this.questionInfoList[parseInt(this.question)].id === index.id) {
        this.clickIndexList.push(index.id);
        playCorrectSound(true, false);
        this.$emit("changeStatus", index);
        this.currentIndex++;
        if (this.question >= this.questionInfoList.length - 1) {
          this.isLastStep = true;
          playCorrectSound();

          startConfetti();
          return;
        } else {
          this.question++;
        }
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-content {
  box-sizing: border-box;
  position: relative;
  width: -moz-fit-content;
  width: 80%;
  height: 77%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  // justify-content: space-between;

  .game-left-box,
  .game-middle-box,
  .game-right-box {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    .game-aside-content {
      width: 100%;
      height: 100%;
      display: flex;

      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
    }
  }

  .game-center-box {
    width: 40%;
    right: -2%;
    // background-repeat: round;
    background-size: cover;
    // height: 63%;
    // top: 10%;
    height: 90%;
    top: 0%;
    height: 63%;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // margin-top: -53%;
      height: 100%;
      width: 100%;
      // top: -5%;
    }
    .number-area {
      width: 80%;
      top: 32%;
      left: 18%;
      padding-top: 40%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }
  .imgType {
  }
}
.number-bg-color {
  background: #224e96;
  box-sizing: border-box;
}

.item-content {
  box-sizing: border-box;
  width: 65%;
  height: 25%;
  z-index: 1;
  border-radius: 15px;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
.card-item {
  box-sizing: border-box;
  width: 90%;
  height: 25%;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .card {
    box-sizing: border-box;
    width: 100%;
    // height: 100%;
    // object-fit: cover;
    border: 10px solid #fff;
    border-bottom: 20px solid #fff;
    border-radius: 20px;
  }
  .yellowBorder {
    border-color: #f4d737;
  }
  .redBorder {
    border-color: #cd4c3f;
  }
}
.item-content-number {
  background-color: #f4d737;
  color: #224e96;
  border-bottom: 8px solid #d1b726;
  border-top: 8px solid #fff2a8;
  pointer-events: none;
}
// .item-content:active {
//   transform: scale(0.8);
// }
.item-content:hover {
  background-color: #912016;
  border-top: 8px solid #6e0c03;
  border-bottom: none;
}

// .objectDisplay {
//   position: absolute;
//   width: 70%;
//   /* bottom: 16%; */
//   height: 90%;
//   left: 15%;
//   top: 5%;
// }
</style>
