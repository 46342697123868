var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c("div", { staticClass: "mainCont" }, [
        _c(
          "div",
          { staticClass: "background" },
          [
            _c("Star", {
              attrs: {
                totalStarNumber: _vm.totalStars,
                currentIndex: _vm.currentIndex,
              },
            }),
            _c(
              "div",
              {
                staticClass: "game-content",
                class: { "number-bg-color": !_vm.lessonType },
              },
              [
                _c("div", { staticClass: "game-left-box" }, [
                  _c(
                    "div",
                    { staticClass: "game-aside-content" },
                    [
                      _vm._l(
                        _vm.asideElementInfo.leftList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 0,
                                  expression: "lessonType === 0",
                                },
                              ],
                              key: index,
                              staticClass: "item-content",
                              class: { "item-content-number": item.done },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              item.pinyin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "pinyin font-pinyin-medium",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "font-hanzi-medium" }, [
                                _vm._v(_vm._s(item.element)),
                              ]),
                            ]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.asideElementInfo.leftList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 1,
                                  expression: "lessonType === 1",
                                },
                              ],
                              key: index + "type-imgleft",
                              staticClass: "card-item",
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "card",
                                class: {
                                  yellowBorder:
                                    _vm.clickIndexList.indexOf(item.id) > -1,
                                  redBorder:
                                    _vm.bingoIndexList.indexOf(item.id) > -1 &&
                                    _vm.currentIndex ===
                                      _vm.questionInfoList.length,
                                },
                                attrs: { src: item.bgImg, alt: "" },
                              }),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "game-middle-box" }, [
                  _c(
                    "div",
                    { staticClass: "game-aside-content" },
                    [
                      _vm._l(
                        _vm.asideElementInfo.MiddleList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 0,
                                  expression: "lessonType === 0",
                                },
                              ],
                              key: index,
                              staticClass: "item-content",
                              class: { "item-content-number": item.done },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              item.pinyin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "pinyin font-pinyin-medium",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "font-hanzi-medium" }, [
                                _vm._v(_vm._s(item.element)),
                              ]),
                            ]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.asideElementInfo.MiddleList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 1,
                                  expression: "lessonType === 1",
                                },
                              ],
                              key: index + "type-imgMiddle",
                              staticClass: "card-item",
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "card",
                                class: {
                                  yellowBorder:
                                    _vm.clickIndexList.indexOf(item.id) > -1,
                                  redBorder:
                                    _vm.bingoIndexList.indexOf(item.id) > -1 &&
                                    _vm.currentIndex ===
                                      _vm.questionInfoList.length,
                                },
                                attrs: { src: item.bgImg, alt: "" },
                              }),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "game-right-box" }, [
                  _c(
                    "div",
                    { staticClass: "game-aside-content" },
                    [
                      _vm._l(
                        _vm.asideElementInfo.rightList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 0,
                                  expression: "lessonType === 0",
                                },
                              ],
                              key: index,
                              staticClass: "item-content",
                              class: { "item-content-number": item.done },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              item.pinyin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "pinyin font-pinyin-medium",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "font-hanzi-medium" }, [
                                _vm._v(_vm._s(item.element)),
                              ]),
                            ]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.asideElementInfo.rightList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lessonType === 1,
                                  expression: "lessonType === 1",
                                },
                              ],
                              key: index + "type-imgRight",
                              staticClass: "card-item",
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "card",
                                class: {
                                  yellowBorder:
                                    _vm.clickIndexList.indexOf(item.id) > -1,
                                  redBorder:
                                    _vm.bingoIndexList.indexOf(item.id) > -1 &&
                                    _vm.currentIndex ===
                                      _vm.questionInfoList.length,
                                },
                                attrs: { src: item.bgImg, alt: "" },
                              }),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._l(_vm.questionInfoList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.question === index,
                          expression: "question === index",
                        },
                      ],
                      key: index + "question",
                      staticClass: "game-center-box",
                      class: { imgType: _vm.lessonType },
                      style: [
                        _vm.lessonType
                          ? {
                              backgroundImage: `url(${require("@/assets/img/16-GAMES/G56-bingo-images/background-card-panda.svg")})`,
                            }
                          : "",
                      ],
                    },
                    [
                      !_vm.lessonType
                        ? _c("img", {
                            staticClass: "objectDisplay",
                            attrs: { src: item.bgImg },
                          })
                        : _vm._e(),
                      _vm.lessonType
                        ? _c("div", { staticClass: "number-area" }, [
                            _c(
                              "div",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [_vm._v(_vm._s(item.pinyin))]
                            ),
                            _c("div", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.hanzi)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }